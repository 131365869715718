import { Suspense, lazy } from 'react'
import  PageErrorBoundary  from '../../components/PageErrorBoundary'
import Loading from '../../components/Loading'

const LocationsPage = lazy(() => import('./Index'))

const SuspendedLocationsPage =  (props : any) => 
<PageErrorBoundary>
<Suspense fallback={<Loading />}>
<LocationsPage {...props} />
</Suspense>
</PageErrorBoundary>

export default SuspendedLocationsPage;