import AppHeader from "./components/AppHeader";
import DashboardPage from "./pages/Dashboard/SuspendedDashboardPage";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import NotFoundPage from "./pages/NotFoundPage";
import IndividualOrderPage from "./pages/IndividualOrderPage/SuspendedIndividualOrderPage";
import LoginPage from "./pages/LoginPage/SuspendedLogInPage";
import LocationsPage from "./pages/LocationsPage/SuspendedLocationsPage";
import { useState } from "react";
import {ToastContainer} from 'react-toastify'
import {
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import ScanQRCodePage from "./pages/ScanQRCode/SuspendedScanQRCode";
import LocationsMenuPage from "./pages/LocationsMenuPage/SuspendedLocationsMenuPage";
import LocationPage from "./pages/LocationPage/SuspendedLocationPage";
import Signup from "./pages/Signup/SuspendedSignupPage";
import 'react-toastify/dist/ReactToastify.css';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import EditLocationPage from "./pages/EditLocationPage/SuspendedEditLocationPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage/SuspendedForgotPasswordPage";
import CustomOrderPage from "./pages/customOrderPage/SuspendedCustomOrderPage";

const App = () => {
  
  const appQueryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: Infinity,
        useErrorBoundary:true,
        suspense:true,
      },
      mutations: {
        useErrorBoundary:true,
      },
    },
  });
  // getShopifyOrdersData({})
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(window.localStorage.getItem('authtoken')?true:false);
  return (
    <QueryClientProvider client={appQueryClient}>
      <ToastContainer
        bodyClassName='successToast'
        position='top-center'
        hideProgressBar
        autoClose={3000}
        stacked
        theme='light'
      />
      <BrowserRouter>
        {isAuthenticated ? <AppHeader /> : undefined}

        <div className="w-full min-h-screen bg-gray-50 p-4">
          <Routes>
            <Route
              path="/login"
              element={<LoginPage setIsAuthenticated={setIsAuthenticated} />}
            />
            <Route
              index
              element={
                isAuthenticated ? <DashboardPage /> : <Navigate to={"/login"} />
              }
             />
             <Route
              path="/scanQR"
              element={
                isAuthenticated ? <ScanQRCodePage /> : <Navigate to={"/login"} />
              }
             />
             <Route path="/forgot-password" element={<ForgotPasswordPage />} />
            <Route path="/location-summary" element={<LocationsPage />}></Route>
            <Route path='/:location-id/location'  element={<LocationPage />}/>
            <Route path='/locations' element={<LocationsMenuPage />} />
            <Route path='/locations/:location-id'  element={<EditLocationPage/>} />
            <Route
              path="/:orderId/"
              element={
                isAuthenticated ? (
                  <IndividualOrderPage />
                ) : (
                  <Navigate to={"/login"} />
                )
              }
            />
            <Route
              path="/:orderId/shopifyOrder"
              element={
                isAuthenticated ? (
                  <IndividualOrderPage />
                ) : (
                  <Navigate to={"/login"} />
                )
              }
            />
            <Route
              path="/:orderId/shopify-order"
              element={
                isAuthenticated ? (
                  <CustomOrderPage />
                ) : (
                  <Navigate to={"/login"} />
                )
              }
            />
            <Route
              path="/signup"
              element={<Signup setIsAuthenticated={setIsAuthenticated} />}
            />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </div>
      </BrowserRouter>
    </QueryClientProvider>
  );
};

export default App;
