import PageErrorBoundary from "../../components/PageErrorBoundary";
import { Suspense, lazy } from "react";
import Loading from "../../components/Loading";

const ForgotPasswordPage = lazy(() => import('./Index'))

const SuspendedForgotPasswordPage = (props:any) =><PageErrorBoundary>
<Suspense fallback={<Loading />}>

<ForgotPasswordPage {...props} />
</Suspense>

</PageErrorBoundary>


export default SuspendedForgotPasswordPage;