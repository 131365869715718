import { NavLink, useNavigate } from "react-router-dom";
import SearchOrdersContainer from "./SuspendedSearchOrdersContainer";
import { useState } from "react";

const AppHeader = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();
  const [resultListOpen,setResultListOpen] = useState(false)
  const onSearchQueryChange = (event: any) => {
    setSearchQuery(event.target.value);
    setResultListOpen(true);
  };
  const navigateToOrder = (id:string)=>{
    setSearchQuery("");
    navigate(id);
    setResultListOpen(false);

  }

  return (
    <header className="w-full border-b border-gray-200 pt-3 sm:pt-0">
      <nav className="bg-white border-gray-200 px-4 lg:px-6 py-2.5 dark:bg-gray-800">
        <div className="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl  ">
          <NavLink to="/" className="flex items-center">
            <img
              src="//www.indianriverdirect.com/cdn/shop/files/NEW_peach_truck_express_logo_06c5a0b4-1b35-40cc-a20a-24c9a0afcf26_280x.png?v=1680530610"
              className="mr-3 h-6 sm:h-9"
              alt="Indian River Direct Logo"
              style={{ height: "90px" }}
            />
          </NavLink>

          <div className="flex items-center lg:order-1">
            <label
              htmlFor="search-bar"
              className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-gray-300"
            >
              Search
            </label>
            <div className="relative mr-3 ">
              <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                <svg
                  className="w-5 h-5 text-gray-500 dark:text-gray-400"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  ></path>
                </svg>
              </div>
              <input
                type="search"
                id="search-bar"
                value={searchQuery}
                onChange={(event) => onSearchQueryChange(event)}
                className="block py-3 px-4 pl-10 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 mr-2"
                placeholder="Search"
                required
              />
              {searchQuery.trim().length !== 0 && resultListOpen ? (
                <SearchOrdersContainer searchQuery={searchQuery} navigateToOrder={navigateToOrder} onModalClosed ={() => setResultListOpen(false)}/>
              ) : undefined}
            </div>

            <NavLink
              to="scanQR"
              className="text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg  px-4 lg:px-5 py-2 lg:py-2.5 mr-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800 flex"
            >
              <svg
                className="w-6 h-6 text-white dark:text-white mr-0 sm:mr-2"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="rgb(29 78 216)"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 4h6v6H4V4Zm10 10h6v6h-6v-6Zm0-10h6v6h-6V4Zm-4 10h.01v.01H10V14Zm0 4h.01v.01H10V18Zm-3 2h.01v.01H7V20Zm0-4h.01v.01H7V16Zm-3 2h.01v.01H4V18Zm0-4h.01v.01H4V14Z"
                />
                <path
                  stroke="currentColor"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M7 7h.01v.01H7V7Zm10 10h.01v.01H17V17Z"
                />
              </svg>
              {window.innerWidth > 452 ? "Scan QR Code" : undefined}
            </NavLink>
          </div>
          {/* <div className="hidden justify-between items-center w-full lg:flex lg:w-auto lg:order-1" id="mobile-menu-2">
                <ul className="flex flex-col mt-4 font-medium lg:flex-row lg:space-x-8 lg:mt-0">
                    <li>
                        <a href="#" className="block py-2 pr-4 pl-3 text-white rounded bg-primary-700 lg:bg-transparent lg:text-primary-700 lg:p-0 dark:text-white" aria-current="page">Home</a>
                    </li>
                </ul>
            </div> */}
        </div>
      </nav>
    </header>
  );
};

export default AppHeader;
