import PageErrorBoundary from "../../components/PageErrorBoundary";
import { Suspense, lazy } from "react";
import Loading from "../../components/Loading";

const LoginPage = lazy(() => import('./Index'))

const SuspendedLoginPage = (props:any) =><PageErrorBoundary>
<Suspense fallback={<Loading />}>

<LoginPage {...props} />
</Suspense>

</PageErrorBoundary>


export default SuspendedLoginPage;