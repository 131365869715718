import { Suspense, lazy } from "react";
import PageErrorBoundary from "../../components/PageErrorBoundary";
import Loading from "../../components/Loading";


const LocationPage = lazy(() => import('./Index'))

const SuspendedLocationPage  = (props:any) =>
<PageErrorBoundary>
<Suspense fallback={<Loading/>}>
<LocationPage {...props}/>
</Suspense>

</PageErrorBoundary>


export default SuspendedLocationPage