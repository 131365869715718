import { Suspense, lazy } from "react";
import PageErrorBoundary from "../../components/PageErrorBoundary";
import Loading from "../../components/Loading";

const ScanQRCodePage = lazy(() => import('./Index'))


const SuspendedScanQRCodePage = (props:any) => (
<PageErrorBoundary>
<Suspense fallback ={<Loading />}>
    <ScanQRCodePage {...props} />
</Suspense>
</PageErrorBoundary>
)


export default SuspendedScanQRCodePage;