import { Suspense, lazy } from 'react'
import  PageErrorBoundary  from '../../components/PageErrorBoundary'
import Loading from '../../components/Loading'

const LocationsMenuPage = lazy(() => import('./Index'))

const SuspendedLocationsMenuPage =  (props : any) => 
<PageErrorBoundary>
<Suspense fallback={<Loading />}>
<LocationsMenuPage {...props} />
</Suspense>
</PageErrorBoundary>

export default SuspendedLocationsMenuPage;