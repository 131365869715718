import { Suspense, lazy } from "react";
import PageErrorBoundary from "../../components/PageErrorBoundary";
import Loading from "../../components/Loading";


const IndividualOrderPage = lazy(() => import('./Index'))

const SuspendedIndividualOrderPage  = (props:any) =>
<PageErrorBoundary>
<Suspense fallback={<Loading/>}>
<IndividualOrderPage {...props}/>
</Suspense>

</PageErrorBoundary>


export default SuspendedIndividualOrderPage