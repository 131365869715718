import PageErrorBoundary from "../../components/PageErrorBoundary";
import { Suspense, lazy } from "react";
import Loading from "../../components/Loading";

const SignupPage = lazy(() => import('./Index'))

const SuspendedSignupPage = (props:any) =><PageErrorBoundary>
<Suspense fallback={<Loading />}>

<SignupPage {...props} />
</Suspense>

</PageErrorBoundary>


export default SuspendedSignupPage;